/*
 * @Date: 2021-07-28 10:20:44
 * @LastEditors: linjituan
 * @LastEditTime: 2021-07-28 17:00:29
 */
import request from '@/utils/request'

// 查询保险管理列表
export function listInsurance (query) {
  return request({
    url: '/iot/insurance/list',
    method: 'get',
    params: query
  })
}

// 查询保险管理详细
export function getInsurance (id) {
  return request({
    url: '/iot/insurance/' + id,
    method: 'get'
  })
}

// 新增保险管理
export function addInsurance (data) {
  return request({
    url: '/iot/insurance',
    method: 'post',
    data: data
  })
}

// 修改保险管理
export function updateInsurance (data) {
  return request({
    url: '/iot/insurance',
    method: 'put',
    data: data
  })
}

// 删除保险管理
export function delInsurance (id) {
  return request({
    url: '/iot/insurance/' + id,
    method: 'delete'
  })
}

// 软删除保险管理
export function changeStatusInsurance (id) {
  return request({
    url: '/iot/insurance/changeStatus/' + id,
    method: 'delete'
  })
}

// 导出保险管理
export function exportInsurance (query) {
  return request({
    url: '/iot/insurance/export',
    method: 'get',
    params: query
  })
}

// 下载用户导入模板
export function importTemplate () {
  return request({
    url: '/iot/insurance/importTemplate',
    method: 'get'
  })
}

// 导入用户
export function importData (data) {
  return request({
    url: '/iot/insurance/importData',
    method: 'post',
    data: data
  })
}
